<template>
  <div class="box">
    <div class="null-page" v-show="yes"></div>
    <el-card class="box-card logistics">
      <div slot="header" class="clearfix">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/member/order_list' }">订单列表</el-breadcrumb-item>
          <el-breadcrumb-item>物流详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-loading="loading">
        <el-tabs v-model="activeParcel">
          <el-tab-pane v-for="(packageItem, packageIndex) in packageList" :key="packageIndex"
            :label="packageItem.package_name" :name="'parcel_' + packageIndex">
            <div class="trace" v-if="packageItem.trace.success && packageItem.trace.list.length > 0">
              <el-timeline>
                <el-timeline-item v-for="(traceItem, traceIndex) in packageItem.trace.list"
                  :timestamp="traceItem.datetime" placement="top" :type="traceIndex == 0 ? 'primary' : ''"
                  :key="traceIndex">
                  <p>{{ traceItem.remark }}</p>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="trace" v-else>
              <p class="empty-wrap" v-if="packageItem.isLogistics">{{ packageItem.trace.reason }}</p>
            </div>
            <ul class="info-wrap">
              <li v-if="packageItem.isLogistics">
                <label>运单号码：</label>
                <span>{{ packageItem.delivery_no || '----' }}</span>
              </li>
              <li>
                <label>物流公司：</label>
                <span>{{ packageItem.express_company_name || '暂无物流' }}</span>
              </li>
              <li v-if="!packageItem.isLogistics">
                <label>物流公司电话：</label>
                <span>{{ packageItem.logis_phone }}</span>
              </li>
              <li v-if="!packageItem.isLogistics" class="img_div">
                <label>货运单：</label>
                <div class="img-box" v-for="(item, index) in packageItem.logis_imgs" :key="index">
                  <img :src="$img(item)" @click="openDialog(item)" />
                </div>
              </li>
            </ul>
            <div v-if="packageItem.remark ? true : false">发货备注：<b>{{ packageItem.remark }}</b></div>
            <ul class="goods-wrap">
              <li v-for="(goodsItem, goodsIndex) in packageItem.goods_list" :key="goodsIndex"
                @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
                <div class="img-wrap"><img :src="$img(goodsItem.sku_image, { size: 'small' })"
                    @error="imageError(packageIndex, goodsIndex)" /></div>
                <p class="sku-name">{{ goodsItem.sku_name }} x {{ goodsItem.num }}</p>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
    <el-dialog :visible.sync="showDialog" title="物流单" width="56%" center>
      <div class="dialog_div">
        <img :src="onImg" @click="hidenDialog" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  defineComponent,
  ref
} from 'vue'
import {
  apiOrderPackageInfo
} from '@/api/order/order';
import {
  mapGetters
} from 'vuex';
export default {
  name: 'logistics',
  components: {},
  data: () => {
    return {
      orderId: 0,
      loading: true,
      activeParcel: 'parcel_0',
      packageList: [],
      yes: true,
      onImg: '',
      showDialog: false,
    };
  },
  created() {
    this.orderId = this.$route.query.order_id;
    if (!this.orderId) this.$router.push({
      path: '/member/order_list'
    });
    this.getOrderPackageInfo();
  },
  computed: {
    ...mapGetters(['defaultGoodsImage'])
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      self.yes = false
    }, 300)
  },
  methods: {
    // 关闭
    hidenDialog() {
      this.showDialog = false;
    },
    // 打开物流单弹窗
    openDialog(item) {
      this.onImg = item;
      this.showDialog = true;
    },
    getOrderPackageInfo() {
      apiOrderPackageInfo({
        order_id: this.orderId
      }).then(res => {
        if (res.code >= 0) {
          this.packageList = res.data;
          this.packageList.forEach(item => {
            if (item.trace.list) {
              item.trace.list = item.trace.list.reverse();
            }
          });
          this.packageList.map(item => {
            if (item.logis_img.length > 0) {
              item.logis_imgs = item.logis_img.split(',');
            }
            // 是快递发货还是物流货运发货？ 快递单：true; 物流货运：false;
            item.isLogistics = true;
            if (item.logis_phone.length > 0 && item.logis_img.length > 0) {
              item.isLogistics = false;
            }
          })
          // console.log(".......this.packageList...", this.packageList);
          this.loading = false;
        } else {
          this.$message({
            message: '未获取到订单包裹信息！',
            type: 'warning',
            duration: 2000,
            onClose: () => {
              this.$router.push({
                path: '/member/order_list'
              });
            }
          });
        }
      }).catch(res => {
        this.loading = false;
      });
    },
    imageError(packageIndex, goodsIndex) {
      this.packageList[packageIndex].goods_list[goodsIndex].sku_image = this.defaultGoodsImage;
    }
  }
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  position: relative;
}

.null-page {
  width: 100%;
  height: 730px;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.logistics {
  .trace {
    .empty-wrap {
      padding: 10px 0;
    }
  }

  .info-wrap {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 0 0 33.3333%;
      margin-bottom: 10px;

      span {
        font-weight: bold;
      }
    }
  }

  .goods-wrap {
    overflow: hidden;
    margin: 10px 0;

    li {
      float: left;
      width: 130px;
      margin-right: 7px;
      cursor: pointer;

      &:nth-child(n + 7) {
        margin-right: 0;
      }

      .img-wrap {
        width: 120px;
      }

      .sku-name {
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: $ns-font-size-sm;
      }
    }
  }
}

.img_div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.img-box {
  width: 180px;
  height: 120px;
}

.dialog_div {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>